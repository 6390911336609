import React, { useContext, useEffect } from 'react';

import classnames from 'classnames';
import {
  AppDataContext,
  inIframe,
  useIblTranslation,
} from '@iblai/ibl-web-react-common';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Profile from '../../modules/profile';
import Logo from '../../modules/header/Logo';
import ChatLLMs from '../../modules/llms/v2/chat-llms';
import { useHeader } from '../../hooks/header/useHeader';
import useEmbedMode from '../../hooks/iframe/useEmbedMode';
import useUserProfile from '../../hooks/user/useUserProfile';
import { useMenus, useTriggers } from '../../hooks/navigation';
import { userIsAdmin, userIsOnTrial } from '../../utils/shared';
import useAnonymousMode from '../../hooks/iframe/useAnonymousMode';
import Helpmenudropdownbox from '../Helpmenudropdownbox/Helpmenudropdownbox';
import Settingsmenudropdownbox from '../Settingsmenudropdownbox/Settingsmenudropdownbox';
import { useTenantCustomization } from '../../hooks/tenant-customization/useTenantCustomization';

import './Header.css';
import { MentorDropdownMenu } from './MentorsDropdownMenu';

const Header = ({ hasBorder, handleMentorClick, showLLMs = true }) => {
  const selectedMentorSearch = useSelector((state) => state.analytics.mentor);
  const header = useSelector((state) => state.navigation.data);
  const auth = useSelector((state) => state.auth?.data);
  const embed = useEmbedMode();
  const anonymous = useAnonymousMode();
  let instructorMode = useSelector((state) => state.navigation.instructorMode);
  if (!auth?.tenant?.is_admin) {
    instructorMode = false;
  }

  const mentors = useSelector((state) => state.mentors);
  const menus = useMenus();
  const {
    handleMentorsMenuBtnClick,
    handleHelpMenuBtnClick,
    handleUserTypeSwitchChange,
    handleMentorToParentCollapse,
  } = useTriggers();

  const { handleGetTenantMetaData } = useTenantCustomization();

  const userProfile = useUserProfile();
  const isMentorIframed = inIframe();

  const iframeCloseButtonEnabled = useSelector(
    (state) => state.navigation.iframeCloseButton
  );

  const { HELP_THREE_DOT_MENU_ENABLED } = useHeader();

  const { t } = useTranslation();

  const { translate } = useIblTranslation();
  const { appData } = useContext(AppDataContext);

  useEffect(() => {
    if (appData.userMetaData?.language) {
      translate(appData.userMetaData.language);
    }
  }, [appData.userMetaData]);

  useEffect(() => {
    handleGetTenantMetaData();
  }, []);

  return (
    <main
      className={classnames('header', {
        border: hasBorder === true,
      })}
      style={{
        marginLeft: embed ? 'unset !important' : hasBorder ? '0px' : '',
        width: embed ? '95%' : '',
        borderLeft: '0px',
      }}
    >
      <div className="left-hand-side">
        {anonymous && (
          <div
            className={`${anonymous ? 'logo-container-anonymous' : 'logo-container'} flex-block-92`}
            style={{ marginLeft: '10px' }}
          >
            <Logo />
          </div>
        )}
        <div
          className="w-layout-hflex ai-expert-header-btn-container all-whiten-in-dark-mode"
          style={{ display: anonymous ? 'none' : 'flex' }}
        >
          {mentors?.mentor && (
            <>
              {handleMentorClick === undefined ? (
                <MentorDropdownMenu />
              ) : (
                <div
                  className="w-layout-hflex default-featured-mentor-label-block"
                  style={{ marginLeft: hasBorder && '0px' }}
                  onClick={
                    userIsAdmin() || userIsOnTrial()
                      ? handleMentorClick
                      : undefined
                  }
                >
                  <div className="show-retrieved-doc-column-button-copy w-embed">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24px"
                      viewBox="0 -960 960 960"
                      width="24px"
                      fill="#646464"
                    >
                      <path d="M480-440q-66 0-113-47t-47-113v-140q0-25 17.5-42.5T380-800q15 0 28.5 7t21.5 20q8-13 21.5-20t28.5-7q15 0 28.5 7t21.5 20q8-13 21.5-20t28.5-7q25 0 42.5 17.5T640-740v140q0 66-47 113t-113 47Zm0-80q33 0 56.5-23.5T560-600v-100H400v100q0 33 23.5 56.5T480-520ZM160-120v-112q0-34 17.5-62.5T224-338q62-31 126-46.5T480-400q66 0 130 15.5T736-338q29 15 46.5 43.5T800-232v112H160Zm80-80h480v-32q0-11-5.5-20T700-266q-54-27-109-40.5T480-320q-56 0-111 13.5T260-266q-9 5-14.5 14t-5.5 20v32Zm240 0Zm0-500Z"></path>
                    </svg>
                  </div>
                  <div className="ai-expert-choose-label">
                    {selectedMentorSearch?.name}
                  </div>
                  {(userIsAdmin() || userIsOnTrial()) && (
                    <img
                      alt=""
                      className="image-48"
                      loading="lazy"
                      src="/images/chevron-down-3.svg"
                    />
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div
        className="w-layout-hflex middle-block-side"
        style={{ marginLeft: 'unset !important' }}
      >
        {showLLMs && userIsAdmin() && instructorMode && !anonymous && (
          <ChatLLMs />
        )}
      </div>
      <div className="right-hand-side">
        {auth?.tenant?.is_admin && !embed && (
          <div className="w-layout-hflex switcher-container">
            <h5 className="switch-label-txt whiten-in-dark-mode">
              {t('Learner')}
            </h5>
            <div className="switch-html-embed w-embed">
              <label className="switch learner-instructor-switch">
                <input
                  defaultChecked={instructorMode}
                  onClick={handleUserTypeSwitchChange}
                  prop-events-value-onchange="handleUserTypeSwitchChange"
                  type="checkbox"
                />
                <span className="slider round">
                  <img
                    alt=""
                    className="left-img"
                    src="/custom-assets/img/graduation-cap.svg"
                  />
                  <img
                    alt=""
                    className="right-img"
                    src="/custom-assets/img/presentation.svg"
                  />
                </span>
              </label>
            </div>
            <h5 className="switch-label-txt whiten-in-dark-mode instructor-label">
              {t('Instructor')}
            </h5>
          </div>
        )}

        <div className="w-layout-hflex header-action-btn-block">
          {(instructorMode || userIsOnTrial()) && !anonymous && (
            <div
              className="header-settings-dropdown w-dropdown"
              data-delay={0}
              data-hover="false"
            >
              <div
                onClick={handleMentorsMenuBtnClick}
                className="header-action-btn all-whiten-in-dark-mode greyish-bg-in-darkmode w-dropdown-toggle"
                prop-events-value-onclick="handleSettingMenuBtnClick"
              >
                <img
                  alt=""
                  className="ai-expert-header-action-icon"
                  loading="lazy"
                  src="/images/cog.svg"
                />
              </div>
              {header?.menus?.settings && (
                <div ref={menus?.refs.settings}>
                  <Settingsmenudropdownbox />
                </div>
              )}
            </div>
          )}
        </div>
        {!anonymous && !userProfile?.hide && <Profile />}

        {HELP_THREE_DOT_MENU_ENABLED && embed && (
          <div
            className="w-layout-hflex header-action-btn-block info-header-action-btn-block"
            style={{ marginRight: '5px' }}
          >
            <div
              className="header-help-dropdown w-dropdown"
              data-delay={0}
              data-hover="false"
            >
              <div
                className="header-action-btn all-whiten-in-dark-mode greyish-bg-in-darkmode w-dropdown-toggle"
                onClick={handleHelpMenuBtnClick}
                prop-events-value-onclick="handleHelpMenuBtnClick"
              >
                <img
                  alt=""
                  className="ai-expert-header-action-icon"
                  loading="lazy"
                  src="/images/more-vertical.svg"
                />
              </div>
              {header?.menus?.help && (
                <div ref={menus?.refs.help}>
                  <Helpmenudropdownbox />
                </div>
              )}
            </div>
          </div>
        )}
        {/*IFRAMED MENTOR CLOSER BUTTON*/}
        {isMentorIframed && iframeCloseButtonEnabled && (
          <div
            className="w-layout-hflex header-action-btn-block"
            style={{ marginLeft: '-10px' }}
          >
            <div
              className="w-layout-hflex header-action-btn all-whiten-in-dark-mode greyish-bg-in-darkmode"
              data-w-id="5e893514-6e31-e1d3-c0f4-275ac21ca450"
              onClick={handleMentorToParentCollapse}
              prop-events-value-onclick="handlePromptGalleryBtnClick"
            >
              <img
                alt=""
                className="ai-expert-header-action-icon"
                loading="lazy"
                src="/images/close-x-blue.svg"
                style={{ width: '32px' }}
              />
            </div>
          </div>
        )}
      </div>
      {mentors?.mentor?.settings?.custom_css &&
        isMentorIframed &&
        anonymous && (
          <style
            dangerouslySetInnerHTML={{
              __html: mentors?.mentor.settings.custom_css,
            }}
          ></style>
        )}
    </main>
  );
};

export default Header;
