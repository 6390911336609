import { useChatRoom } from './index';
import { chatActions } from '../../lib/redux/chat/slice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import useApi from '../api/base/useApi';

export default function useSuggestedPrompt() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth.data);
  const { mode } = useSelector((state) => state.embed);
  const [selectedPrompt, setSelectedPrompt] = useState('');
  const chat = useSelector((state) => state.chat.data);
  const { generating, handleUserPromptFormSubmit } = useChatRoom();
  const api = useApi();

  const handleSuggestedPromptSelection = (prompt) => {
    setSelectedPrompt(prompt);
    dispatch(chatActions.chatUpdated({ ...chat, prompt: prompt }));
  };

  const deleteSuggestedPrompt = async (id) => {
    const url = `/api/ai-prompt/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/prompt/${id}/`;
    return await api.remove(url);
  };

  useEffect(() => {
    if (chat.prompt && selectedPrompt === chat.prompt) {
      document.getElementById('user-prompt-submit-btn')?.click();
    }
  }, [chat.prompt, selectedPrompt]);

  useEffect(() => {
    dispatch(chatActions.chatUpdated({ ...chat, generating: generating }));
  }, [generating, dispatch]);

  return { handleSuggestedPromptSelection, deleteSuggestedPrompt };
}
