import React, { useEffect, useState } from 'react';
import useApi from '../../../hooks/api/base/useApi';
import { useSelector } from 'react-redux';
import { useTriggers } from '../../../hooks/navigation';
import { toast } from 'react-hot-toast';
export default function MentorAPI() {
  const api = useApi();
  const {
    handleAddAPICredentialBtnClick,
    handleCurrentApiKeyOrgUpdated,
    handleChangeAPICredentialBtnClick,
    handleDeleteAPICredentialBtnClick,
  } = useTriggers();
  const auth = useSelector((state) => state.auth.data);
  const {currentApiKeyOrg, newlyCreatedApiKeys} = useSelector((state) => state.navigation);
  const {apiKeyStatus} = useSelector((state) => state.navigation);
  const [apiKeys, setApiKeys] = useState([]);
  let [apiKeyLoaded, setApiKeyLoaded] = useState(false);
  const [orgs, setOrgs] = useState([]);
  function convertISOTimeString(s) {
    if (!s) return "N/A"
    const d = new Date(s)
    return d.toLocaleDateString("en-US", {"month": "long", "day": "numeric", "year": "numeric"})
  }
  const hideKey = (key) => {
    const shortenedKey = key.substring(0, 5);
    return shortenedKey + "..."
  }
  async function loadApiKeys() {

    const apiKeyUrl = `/api/core/platform/api-tokens/?platform_key=${currentApiKeyOrg}`;
    const response = await api.get(apiKeyUrl, null, localStorage.getItem("dm_token"));
    if (response?.data) {
      setApiKeys(response.data);
    }
  }
  const copyKey = (name) => {
    const key = newlyCreatedApiKeys[name];
    if (!key) {
      return
    }
    navigator.clipboard.writeText(key).then(
      () => toast.success('Copied to clipboard')
    )
  }
  async function loadOrgs() {
    const orgsUrl = `/api/core/users/platforms/?username=${auth?.user?.user_nicename}`;
    const response = await api.get(orgsUrl, null, localStorage.getItem('dm_token'));
    if (response?.data) {
      response.data.forEach(
        org => {
          const is_admin = org.is_admin || org.is_staff
          if (is_admin && org.user_active && org.active) {
            setOrgs(orgs.concat([org.key]));
          }
        }
      )
    }
  }

  useEffect(() => {
    loadOrgs()
  }, [currentApiKeyOrg]);
  useEffect(() => {
    if (!currentApiKeyOrg) return;
    loadApiKeys().then(
      () => setApiKeyLoaded(true)
    )

  }, [apiKeyLoaded, apiKeyStatus, currentApiKeyOrg]);
  useEffect(() => {
    if (orgs.length < 1) return;
    if (orgs.includes(auth?.tenant?.key)) {
      handleCurrentApiKeyOrgUpdated(auth?.tenant?.key);
    } else {
      handleCurrentApiKeyOrgUpdated(orgs[0]);
    }
  }, [orgs]);
  return (
    <div className="w-layout-vflex edit-mentor-system-prompt-tab">
      <div className="w-layout-hflex moderaion-proactive-prompt-block">
        <div className="w-layout-vflex prompt-category-container">
          Your secret API keys are listed below. Please note that we do not
          display your secret API keys again after you generate them.
          <br />
          <br />
          Do not share your API key with others, or expose it in the browser or
          other client-side code. In order to protect the security of your
          account, IBL may also automatically rotate any API key that we've
          found has leaked publicly.
          <br />
          <br />
          <div className="w-layout-vflex table-container">
            <div className="w-layout-hflex table-header api-key-table-header">
              <div className="api-name-block">
                <h5 className="table-title">NAME</h5>
              </div>
              <div className="api-key-block">
                <h5 className="table-title">KEY</h5>
              </div>
              <div className="api-created-block">
                <h5 className="table-title">CREATED</h5>
              </div>
              <div className="api-last-used-block">
                <h5 className="table-title">EXPIRES</h5>
              </div>
              <div className="api-action-block"></div>
            </div>
            {apiKeyLoaded && apiKeys.map(object => {
              return (
                <div className="w-layout-hflex table-body-row">
                  <div className="api-name-block">
                    <div className="table-text">{object.name}</div>
                  </div>
                  <div className="api-key-block">
                    <div className="table-text">
                      {newlyCreatedApiKeys[object.name]
                        ? hideKey(newlyCreatedApiKeys[object.name])
                        : 'N/A'}
                      {newlyCreatedApiKeys[object.name] && (
                       <><span>&nbsp;&nbsp;&nbsp;</span><img
                          loading="lazy"
                          src="/images/clipboard.svg"
                          alt=""
                          onClick={() => copyKey(object.name)}
                          className="api-action-icon whiten-in-dark-mode"
                        /></>
                      )}
                    </div>
                  </div>
                  <div className="api-created-block">
                    <div className="table-text">
                      {convertISOTimeString(object.created)}
                    </div>
                  </div>
                  <div className="api-last-used-block">
                    <div className="table-text">
                      {convertISOTimeString(object.expires)}
                    </div>
                  </div>
                  <div className="api-action-block">
                    <div className="w-layout-hflex api-action-container">
                      {/*    <div className="api-action-icon-container greyish-bg-in-darkmode" onClick={() => handleChangeAPICredentialBtnClick(*/}
                      {/*      {name: object.name, value: object.value}*/}
                      {/*    )}>*/}
                      {/*      <img*/}
                      {/*        loading="lazy"*/}
                      {/*        src="/images/edit.svg"*/}
                      {/*        alt=""*/}
                      {/*        className="api-action-icon whiten-in-dark-mode"*/}
                      {/*      />*/}
                      {/*    </div>*/}
                      <div
                        className="api-action-icon-container greyish-bg-in-darkmode"
                        onClick={() =>
                          handleDeleteAPICredentialBtnClick({
                            name: object.name,
                            value: object.value,
                          })
                        }
                      >
                        <img
                          loading="lazy"
                          src="/images/trash-2.svg"
                          alt=""
                          className="api-action-icon whiten-in-dark-mode"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            { !apiKeyLoaded &&
            <div className="w-layout-hflex table-body-row">
              <div className="api-name-block">
                <div className="table-text">Loading...</div>
              </div>
              <div className="api-key-block">
                <div className="table-text"></div>
              </div>
              <div className="api-created-block">
                <div className="table-text"></div>
              </div>
              <div className="api-last-used-block">
                <div className="table-text"></div>
              </div>
              <div className="api-action-block">
                <div className="w-layout-hflex api-action-container">
                  <div className="api-action-icon-container greyish-bg-in-darkmode">
                  </div>
                  <div className="api-action-icon-container greyish-bg-in-darkmode">
                  </div>
                </div>
              </div>
            </div>
            }

          </div>
          <div
            className="button-with-icon users-settings-invite-btn"
            data-w-id="0e9ebab4-8fa1-448a-be9c-fbf5adcc199f"
             onClick={handleAddAPICredentialBtnClick}
          >
            <img
              alt=""
              className="button-icon hidden"
              loading="lazy"
              src="/images/user-black-thick.svg"
              width={70}
            />
            <div className="button-text">Create New</div>
          </div>
        </div>
      </div>
      <h4 className="heading-7 less-bold">Default Organizations</h4>
      <div className="text-block-209">
        If you belong to multiple organizations, this settings controls which
        one of them is used as the default when making requests with the API
        keys above.
      </div>
      <div className="form-block-2 w-form">
        <form
          id="email-form-3"
          name="email-form-3"
          data-name="Email Form 3"
          method="get"
          data-wf-page-id="672a4c49d8fa05e0362eced4"
          data-wf-element-id="804f6623-bdba-b054-6e61-1b7e2462adb3"
          aria-label="Email Form 3"
        >
          <select
            id="default-organization"
            name="default-organization"
            data-name="default-organization"
            aria-label="Select Default Organization"
            className="select-field w-select"
            disabled="disabled"
          >
            <option value={currentApiKeyOrg}>{currentApiKeyOrg}</option>
          </select>
        </form>
        <div
          className="w-form-done"
          tabIndex="-1"
          role="region"
          aria-label="Email Form 3 success"
        >
          <div>Thank you! Your submission has been received!</div>
        </div>
        <div
          className="w-form-fail"
          tabIndex="-1"
          role="region"
          aria-label="Email Form 3 failure"
        >
          <div>Oops! Something went wrong while submitting the form.</div>
        </div>
      </div>
      <div className="text-block-26">
        Note: You can also specify which organization to use for each API
        request
      </div>
    </div>
  );
}
