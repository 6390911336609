import Suggestedpromptbox from '../../../components/Suggestedpromptbox/Suggestedpromptbox';
import DocumentUploadProgress from './document-upload-progress';
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useDocumentUpload from '../../../hooks/chat-room/useDocumentUpload';
import { useTriggers } from '../../../hooks/navigation';
import useAnonymousMode from '../../../hooks/iframe/useAnonymousMode';
import DocumentUpload from './document-upload';
import VoiceChat from './voice-chat';
import { removeHtmlTags } from '../../../utils/shared';
import useSuggestedPrompt from '../../../hooks/chat-room/useSuggestedPrompt';

export default function ChatRoomForm({
  handleUserPromptFormSubmit,
  handlePromptChange,
  handleStopRespondingBtnClick,
  streaming,
  generating,
}) {
  const textareaRef = React.useRef(null);
  const chat = useSelector((state) => state.chat.data);
  const sessionId = useSelector((state) => state.chat.sessionId);
  const { t } = useTranslation();
  const mentors = useSelector((state) => state.mentors);
  const navigation = useSelector((state) => state.navigation.data);
  const { handlePromptFileUploadBtnClick, file, uploading, handleFileChange } =
    useDocumentUpload();
  const { handleAIAudioCallBtnClick } = useTriggers();
  const anonymous = useAnonymousMode();
  const { mode } = useSelector((state) => state.embed);
  const { recording, processingRecording, showDisclaimer } = useSelector(
    (state) => state.chat
  );

  React.useEffect(() => {
    const textarea = textareaRef.current;

    const handleKeyUp = () => {
      if (textarea) {
        textarea.style.height = 'auto';
        const scrollHeight = textarea.scrollHeight;
        textarea.style.height = `${scrollHeight}px`;
      }
    };

    textarea?.addEventListener('keyup', handleKeyUp);

    return () => {
      textarea?.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const urlParams = new URLSearchParams(window.location.search);
  const embedPreview = urlParams.get('internalPreview') === 'true';
  const enableVoiceChat =
    process.env.REACT_APP_IBL_ENABLE_AUDIO_VOICE_CHAT === 'true';
  const enableWebRtcChat =
    process.env.REACT_APP_IBL_ENABLE_WEBRTC_CHAT === 'true';

  const DISCLAIMER_TEXT = process.env.REACT_APP_IBL_DISCLAIMER_TEXT;

  const handlePromptInputKeyPress = (event) => {
    if (
      event.keyCode === 13 &&
      !event.shiftKey &&
      event.target.value.trim().length > 0
    ) {
      event.preventDefault();
      document.getElementById('user-prompt-submit-btn')?.click();
    }
  };
  return (
    <footer
      className="bottom-area"
      style={mode === 'advanced' ? { padding: 0 } : {}}
    >
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        {(streaming || generating) && (
          <a
            className="stop-responding-btn primary-color-in-dark-mode primary-border-in-dark-mode"
            href="#"
            prop-events-names="onClick"
            onClick={(event) => {
              event.preventDefault();
              handleStopRespondingBtnClick();
            }}
            prop-events-value-onclick="handleStopRespondingBtnClick"
          >
            {t('Stop Responding')}
          </a>
        )}
        {uploading && <DocumentUploadProgress file={file} />}
        <div className="w-layout-hflex file-uploading-block hidden">
          <div className="w-layout-hflex single-file-uploading-box">
            <div className="w-layout-hflex file-upload-status-icon-block">
              <div className="btn-spinner-block-2 w-embed">
                <div className="lds-ring">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
            <div className="w-layout-vflex file-upload-info-block">
              <h6 className="file-upload-name-block">some-file-name...</h6>
              <div className="file-upload-extension-block">PDF</div>
            </div>
            <div className="w-layout-vflex file-upload-closer-block">
              <img
                loading="lazy"
                src="/images/x-circle.svg"
                alt=""
                className="file-upload-closer"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="form-block-copy black-bg-dark-mode w-form"
        style={
          mode === 'advanced'
            ? {
                paddingLeft: 0,
                paddingTop: '8px',
                backgroundColor: 'var(--accent2) !important',
              }
            : {}
        }
      >
        <form
          method="get"
          name="wf-form-user-prompt-label"
          data-name="user-prompt-label"
          id="wf-form-user-prompt-label"
          prop-events-value-onsubmit="handleUserPromptFormSubmit"
          prop-events-names="onSubmit"
          onSubmit={handleUserPromptFormSubmit}
          className="form"
          data-wf-page-id="672a4c49d8fa05e0362eced4"
          data-wf-element-id="b7e282f1-a51e-0660-18f7-59bf4fafad5c"
          aria-label="user-prompt-label"
        >
          <div
            data-w-id="b7e282f1-a51e-0660-18f7-59bf4fafad5d"
            className="w-layout-hflex new-chat-block whiten-border-in-dark-mode all-whiten-in-dark-mode"
          >
            <img
              loading="lazy"
              src="/images/plus.svg"
              alt=""
              className="image-34"
            />
            <div className="tooltip tooltip-new-chat">New Chat</div>
          </div>
          <nav style={{ width: '100%' }}>
            <textarea
              ref={textareaRef}
              className="user-prompt-textarea-field-copy black-bg-dark-mode whiten-border-in-dark-mode whiten-in-dark-mode w-input"
              autoComplete="on"
              autoFocus
              maxLength="5000"
              name="field-10"
              data-name="Field 10"
              id="user-prompt-textarea"
              value={chat.prompt}
              title={embedPreview ? 'Chat is disabled in preview mode' : ''}
              style={
                mode === 'advanced'
                  ? {
                      paddingTop: 0,
                      backgroundColor: 'var(--accent2) !important',
                      maxHeight: '250px',
                    }
                  : { maxHeight: '250px' }
              }
              onChange={handlePromptChange}
              disabled={mode !== 'advanced' && !sessionId}
              placeholder={
                recording
                  ? t('Listening...')
                  : processingRecording
                    ? t('Processing...')
                    : mode === 'advanced'
                      ? t('Ask a question or select a prompt')
                      : t('Enter a Prompt Here')
              }
              tabIndex="99"
              onKeyDown={handlePromptInputKeyPress}
            ></textarea>
            <div className="w-layout-hflex flex-block-24">
              <div className="w-layout-hflex flex-block-372">
                <div className="w-layout-hflex flex-block-25">
                  <div className="w-layout-hflex mentor-footer-action-block">
                    {mode !== 'advanced' && <Suggestedpromptbox />}
                    <div className="w-layout-hflex mentor-footer-btn mentor-footer-action-btn-focused mentor-file-upload-btn">
                      <img
                        loading="lazy"
                        src="/images/paperclip.svg"
                        alt=""
                        className="mentor-footer-action-icon"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="w-layout-hflex flex-block-373"
                style={mode === 'advanced' ? { columnGap: '4px' } : {}}
              >
                {!anonymous && (
                  <DocumentUpload
                    handlePromptFileUploadBtnClick={
                      handlePromptFileUploadBtnClick
                    }
                    handleFileChange={handleFileChange}
                  />
                )}

                {process.env.REACT_APP_IBL_ENABLE_AUDIO_VOICE_CHAT === 'true' &&
                  !anonymous && <VoiceChat />}

                {!anonymous &&
                  process.env.REACT_APP_IBL_ENABLE_WEBRTC_CHAT === 'true' && (
                    <div
                      className="has-custom-tooltip"
                      data-w-id="ac150336-cb25-4850-e62d-80496475ceab"
                      prop-events-names="onClick"
                      onClick={handleAIAudioCallBtnClick}
                      prop-events-value-onclick="handleAIAudioCallBtnClick"
                      style={{ position: 'relative' }}
                    >
                      <div
                        style={{
                          position: 'absolute',
                          display: 'none',
                          right: '10px',
                          bottom: '15px',
                          maxWidth: '180px',
                        }}
                        className="tooltip"
                      >
                        {t('Make AI Audio Chat')}
                      </div>
                      <div
                        className="code-embed-6 w-embed"
                        style={{ display: 'flex' }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="24px"
                          viewBox="0 -960 960 960"
                          width="24px"
                          fill="#919191"
                        >
                          <path d="M798-120q-125 0-247-54.5T329-329Q229-429 174.5-551T120-798q0-18 12-30t30-12h162q14 0 25 9.5t13 22.5l26 140q2 16-1 27t-11 19l-97 98q20 37 47.5 71.5T387-386q31 31 65 57.5t72 48.5l94-94q9-9 23.5-13.5T670-390l138 28q14 4 23 14.5t9 23.5v162q0 18-12 30t-30 12ZM241-600l66-66-17-94h-89q5 41 14 81t26 79Zm358 358q39 17 79.5 27t81.5 13v-88l-94-19-67 67ZM241-600Zm358 358Z"></path>
                        </svg>
                      </div>
                    </div>
                  )}
                {mode !== 'advanced' ? (
                  <div className="w-layout-vflex flex-block-374">
                    <div
                      className="code-embed-2 w-embed"
                      style={{ display: 'flex' }}
                      onClick={
                        generating || streaming || embedPreview
                          ? () => {}
                          : handleUserPromptFormSubmit
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24px"
                        viewBox="0 -960 960 960"
                        width="24px"
                        fill="#FFFFFF"
                      >
                        <path d="M440-160v-487L216-423l-56-57 320-320 320 320-56 57-224-224v487h-80Z"></path>
                      </svg>
                    </div>
                  </div>
                ) : (
                  <div
                    onClick={
                      generating || streaming || embedPreview
                        ? () => {}
                        : handleUserPromptFormSubmit
                    }
                    className="navbar-icon-copy"
                  >
                    send
                  </div>
                )}
              </div>
            </div>
          </nav>
          <input
            id="user-prompt-submit-btn"
            type="submit"
            data-wait="Please wait..."
            disabled={generating || streaming || embedPreview}
            className="submit-button w-button"
            value=""
          />
        </form>
      </div>
      {mode === 'advanced' && <Suggestedpromptbox />}
    </footer>
  );
}
